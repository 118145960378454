<template>
	<supportLayout>
		<div class="p-verifications full_page">
			<div class="container">
				<aside>
					<verifications-filter :refresh="refreshFilter" :active-status="status"></verifications-filter>
				</aside>
				<main :class="{is_expanded: expanded.length, zoomed_img: ZOOMED_IMG}">
					<v-data-table
						:headers="headers"
						:items="requests"
						:options.sync="options"
						:server-items-length="COUNT_ARTIST_VERIFICATION_REQUESTS"
						:loading="loading"
						:single-expand="singleExpand"
						:expanded.sync="expanded"
						@click:row="rowClicked"
						:class="{is_expanded: expanded.length, zoomed_img: ZOOMED_IMG}"
						class="admin-table">

						<template v-slot:item.photo="{ item }">
							<v-img
								class="round"
								lazy-src="https://via.placeholder.com/80"
								max-width="50"
								min-width="50"
								min-height="50"
								max-height="50"
								:src="getImageUrl(item.owner.photo)"
							></v-img>
						</template>

						<template v-slot:item.status="{ item }">
							<v-chip :color="getColor(item.status)" dark>
								{{ item.status }}
							</v-chip>
						</template>

						<template v-slot:item.createdAt="{ item }">
							{{ item.createdAt | moment("HH:mm DD MMM YYYY") }}
						</template>

						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length" class="item-details" :class="{is_expanded: expanded.length, zoomed_img: ZOOMED_IMG}">
								<verification @verification_status_updated="verificationUpdated" :id="item.id"></verification>
							</td>
						</template>
					</v-data-table>
				</main>
			</div>
		</div>
	</supportLayout>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import supportLayout from "@/layouts/supportLayout.vue";
import VerificationsFilter from "@/components/manage/verificationsFilter";
import Verification from "@/components/manage/verification";

export default {
	name: "verifications",
	props: ['status'],
	components: {
		supportLayout,
		Verification,
		VerificationsFilter,
	},
	data: () => ({
		apiUrl: process.env.VUE_APP_API_URL,
		requests: [],
		totalRequests: 0,
		loading: true,
		refreshFilter: 0,
		options: {
			itemsPerPage: 10,
			sortDesc: [true],
			sortBy: ['createdAt']
		},
		singleExpand: false,
		expanded: [],
		headers: [
			{text: 'Photo', value: 'photo'},
			{
				text: 'User nickname',
				align: 'start',
				sortable: false,
				value: 'owner.nickname',
			},
			{text: 'Email', value: 'email'},
			{text: 'Artist Requested', value: 'artist.name'},
			{text: 'Status', value: 'status'},
			{text: 'Created At', value: 'createdAt'},
		],
	}),
	computed: {
		...mapGetters([
			'ARTIST_VERIFICATION_REQUEST',
			'ARTIST_VERIFICATION_REQUESTS',
			'COUNT_ARTIST_VERIFICATION_REQUESTS',
			'ZOOMED_IMG'
		]),
	},
	watch: {
		status: {
			handler() {
				this.getDataFromApi();
				this.expanded = [];
			},
		},
		options: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	mounted() {

	},
	methods: {
		...mapActions(['GET_ARTIST_VERIFICATION_REQUESTS', 'GET_ARTIST_VERIFICATION_REQUEST']),
		getApiParams() {
			// this.options = { sortBy, sortDesc, page, itemsPerPage }
			const params = {
				page: this.options.page,
				itemsPerPage: this.options.itemsPerPage < 0 ? 99999 : this.options.itemsPerPage,
			}

			for (let i = 0; i < this.options.sortBy.length; i++) {
				params[`order[${this.options.sortBy[i]}]`] = this.options.sortDesc[i] === true ? 'desc' : 'asc';
			}

			if (this.status) {
				if (this.status.toLowerCase() === 'completed') {
					params['status'] = ['approved', 'denied', 'banned'];
				} else {
					params['status'] = this.status.toLowerCase();
				}
			}

			return params;
		},
		getDataFromApi() {
			this.loading = true

			this.GET_ARTIST_VERIFICATION_REQUESTS(this.getApiParams())
				.then(() => {
					this.loading = false;
					this.requests = this.ARTIST_VERIFICATION_REQUESTS;
				})
				.catch(err => {
					this.loading = false;
					console.log(`GET_ARTIST_VERIFICATION_REQUESTS, ${err}`);
				})
		},
		getColor(status) {
			switch (status) {
				case 'banned':
				case 'denied':
					return 'red';
				case 'pending':
					return 'orange';
				case 'approved':
				case 'completed':
					return 'green';
				default:
					return 'grey';
			}
		},
		getImageUrl(mediaObject) {
			if (!mediaObject) {
				return '';
			}

			return this.apiUrl + mediaObject.contentUrl
		},
		rowClicked(item, row) {
			row.expand(!row.isExpanded);
			// console.log('item', item);
			// console.log('row', row);
			// this.expanded = [item.id];
		},
		verificationUpdated(verificationRequest) {
			this.refreshFilter++;

			this.requests.forEach(item => {
				if (item.id === verificationRequest.id) {
					item.status = verificationRequest.status;
				}
			});

			// refresh admin notification on header
			this.$store.dispatch('GET_ADMIN_NOTIFICATIONS');
		}
	},
}
</script>

<style lang="scss">
@import "@/assets/styles/base/icons/_formComponents.scss";
.p-verifications {
	.v-chip {
		position: static;
		&:hover {
			&:before {
				content: none;
			}
		}
	}

	.v-image {
		//position: static;
		&.round {
			border-radius: 50%;
		}
	}

	.container {
		display: flex;

		aside {
			border-radius: 8px;
			width: 300px;
			margin-right: 30px;
		}

		main {
			flex: 1;
			&.is_expanded {
				&.zoomed_img {
					tr:not(.v-data-table__expanded) {
						z-index: -1;
						position: relative;
					}
				}
				//border: 1px solid red;
				.zoom-box {
					.image-container {
						> img {
							//border: 1px solid red;
						}
					}
				}
			}
		}

		.item-details {
			position: relative;
			z-index: 0;
		}
	}

	.theme--dark.v-data-table.admin-table {
		background-color: transparent;

		thead th {
			&:first-child {
				border-radius: 8px 0 0;
			}

			&:last-child {
				border-radius: 0 8px 0 0;
			}
		}

		th.sortable, th.sort_by {
			display: table-cell;
		}

		tr:hover {
			background: inherit;
		}

		tr:hover:not(.v-data-table__expanded) {
			td:not(.item-details), th {
				background-color: #282C47;
				border-radius: inherit;
			}
		}

		tr.v-data-table__expanded td {
			border-bottom: none !important;

			&:first-child {
				border-radius: 0 0 0 8px;
			}

			&:last-child {
				border-radius: 0 0 8px 0;
			}
		}

		tr.v-data-table__expanded + tr td {
			border-top: none !important;

			&:first-child {
				border-radius: 8px 0 0 0;
			}

			&:last-child {
				border-radius: 0 8px 0 0;
			}
		}

		td, th {
			vertical-align: middle;
			background-color: #1B1F34;
			cursor: pointer;

			&.item-details {
				padding: 8px 0;
				background-color: transparent;
				cursor: inherit;
			}
		}

		.v-data-footer {
			background-color: #1B1F34;
			border-radius: 0 0 8px 8px;
			.v-data-footer__icons-before {
				> button {
					@include verticalArrow('7481A6');
					background-position: center center;
					background-size: 16px;
					transform: rotate(90deg);
				}
			}
			.v-data-footer__icons-after {
				> button {
					@include verticalArrow('7481A6');
					background-position: center center;
					background-size: 16px;
					transform: rotate(-90deg);
				}
			}
		}
	}
}
</style>
